//================================================================
//  Component: Tasks Pane
//================================================================
//
//  Purpose: Links 'application' or 'document' documents to a 'requestid'
//
//  Properties:
//    - document = {useState, this contains the application or agreement document}
//    - setDocument = {useState, used to determine if the modal is open/closed and contains the application/agreement doc}
//
//  TasksPane:
//    <TasksPane
//      document={taskPane}
//      setDocument={setTaskPane}
//    ></TasksPane>    

//================================================================

//Libraries
import React, { useState, useContext, useEffect, useReducer } from 'react';

//Contexts
import { SetToast } from '../../Library/GlobalContexts';

//Components
import StatusLabel from '../StatusLabel/StatusLabel';
import ExportToCSV from '../ExportToCSV/ExportToCSV';
import Tooltip from '../Tooltip/Tooltip';

//Functions
import GetCollection from '../../Library/GetCollection';
import AddRemoveDocumentArray from '../../Library/AddRemoveDocumentArray';

//Images
import IconSearch from '../Images/Icon_Search_Grey.svg';
import ClearSearch from '../Images/Icon_Clear_Grey.svg';
import Add from '../Images/Icon_Add_Teal.svg';
import AddDisabled from '../Images/Icon_AddDisabled_Teal.svg';
import LoadingIcon from '../Images/Image_Loading_Ripple.svg';

export default function TasksPane({
    document,
    setDocument,
}) {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------
    
    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to determine the status of the pane > 'pending', 'onload'
    const [paneStatus, setPaneStatus] = useState('pending');

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store details about the requests
    const [requestData, setRequestData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'allRequests': [],
            'linkedRequests': [],
            'filteredRequests': [],
            'addedRequests': [],
            'removedRequests': [],

            // Add Requests Search
            'addRequestsSearchInput': '',
            'addRequestSearchResults': [],

            // Table Search
            'tableSearchInput': ''
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Search for existing requests to add to application/agreement
    const handleAllRequestsSearch = (value) => {

        const searchResults = requestData?.allRequests?.filter((object) =>
            object?.requestid?.toLowerCase().includes(value.toLowerCase())

        );

        // Turn Dropdown on if field is not empty
        setRequestData({ 
            'addRequestSearchResults': searchResults
        });

    };

    // Used to search the table search
    const handleTableSearch = (value) => {

        setRequestData({
            'tableSearchInput': value
        });

        // Reset the Filter
        if (value.length === 0) {
            setRequestData({
                'filteredRequests': requestData?.linkedRequests
            });
        };

        // Search Filter
        //  1. requestid
        //  2. createdby.givenname
        //  3. createdby.surname
        //  4. createdby.email
        //  5. status
        const searchResults = requestData?.linkedRequests?.filter((object) =>
            object?.requestid?.toLowerCase().includes(value.toLowerCase()) ||
            object?.tasktype?.toLowerCase().includes(value.toLowerCase()) ||
            object?.createdby?.email?.toLowerCase().includes(value.toLowerCase()) ||
            object?.status?.toLowerCase().includes(value.toLowerCase())
        );

        setRequestData({
            'filteredRequests': searchResults
        });

    }

    // Submits form changes
    const handleSubmit = () => {


        const docWritePromises = [];

        // If Linking Request to Application
        if (document.agreementid !== undefined) {

            // Add Requests
            requestData?.addedRequests?.forEach((object) => {

                docWritePromises.push(
                    AddRemoveDocumentArray('agreements', document.agreementid, 'requestid', object?.requestid, 'add'),
                );

            });

            // Remove Requests
            requestData?.removedRequests?.forEach((object) => {

                docWritePromises.push(
                    AddRemoveDocumentArray('agreements', document.agreementid, 'requestid', object?.requestid, 'remove'),
                );

            });

        }            
        
        // If Linking Request to Application
        if (document.applicationid !== undefined) {

            // Add Requests
            requestData?.addedRequests?.forEach((object) => {

                docWritePromises.push(
                    AddRemoveDocumentArray('applications', document.applicationid, 'requestid', object?.requestid, 'add'),
                );

            });

            // Remove Requests
            requestData?.removedRequests?.forEach((object) => {

                docWritePromises.push(
                    AddRemoveDocumentArray('applications', document.applicationid, 'requestid', object?.requestid, 'remove'),
                );

            });

        }

        // Settle promises
        Promise.all(docWritePromises)
        .then(() => {

            // Reset and Close Side Pane
            setPaneStatus('pending');
            setDocument(undefined);
            setRequestData({
                'allRequests': [],
                'linkedRequests': [],
                'filteredRequests': [],
                'addedRequests': [],
                'removedRequests': [],
    
                // Add Requests Search
                'addRequestsSearchInput': '',
                'addRequestSearchResults': [],
    
                // Table Search
                'tableSearchInput': ''
            });

        })
        .catch((error) => {
    
            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to save changes',
            }); 

        });

    }
    
    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Onload > Get all tasks 
    useEffect(() => {

        // Close the pane if there are issues
        if (document === undefined) return;
        if (document?.requestid === undefined) return;
        if (paneStatus === 'onload') return;
        
        GetCollection('tasks')
        .then((allRequests) => {

            if (document.agreementid !== undefined) {
                allRequests = allRequests.filter((tsk) => tsk.tasktype === 'Commercial Review');
            }

            if (document.applicationid !== undefined) {
                allRequests = allRequests.filter((tsk) => tsk.tasktype === 'Business Request');
            }

            // Use Tasks document to get tasks
            const tempTskList = [];
               
            document?.requestid.forEach(object => {
                const filteredTasks = allRequests.filter((tsk) => tsk.requestid === object);
                tempTskList.push(...filteredTasks);
            });

            setRequestData({
                'allRequests': allRequests,
                'linkedRequests': tempTskList,
                'filteredRequests': tempTskList,
                'addedRequests': [],
                'removedRequests': [],
            });

            setPaneStatus('onload');

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': `Failed to get task - ${error}` ,
            });

        });

    // eslint-disable-next-line
    }, [document]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (document === undefined) return null;

    //====================================================
    //  Pending
    //====================================================

    if (paneStatus === 'pending') {
        return (
            <div className='Pane-Background'>
                <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0 items-center'>

                    <img className='w-[200px] self-center' alt='loading-circle-icon' src={LoadingIcon}></img>

                    <button className='Primary-Button w-fit self-center' onClick={() => {

                        setDocument(undefined);
                        setPaneStatus('pending');

                    }}>
                        Cancel
                    </button>

                </dialog >
            </div>
        )
    }

    //====================================================
    //  Onload
    //====================================================

    else if (paneStatus === 'onload') {
        return (
            <div className='Pane-Background'>
                <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0'>
    
                    {/* ------------------------------------------------------ */}
                    {/*  Header Container                                      */}
                    {/* ------------------------------------------------------ */}
                    
                    <div className='flex flex-col'>
    
                        {/* ------------------------------------------------------ */}
                        {/*  Header                                                */}
                        {/* ------------------------------------------------------ */}
    
                        <div className='flex flex-col gap-1'>
    
                            {/* Title */}
                            <h5>Linked Requests | {document.poanumber !== undefined ? document.poanumber : document.applicationname !== undefined ? document.applicationname : ''}</h5>
                            <span className='text-[#424242] text-sm' hidden={document?.vendor?.vendorname?.length === 0}>
                                {document.vendor.vendorname}
                            </span>
    
                            {/* Border - Bottom */}
                            <hr className='my-2'></hr>
    
                        </div>
    
                        {/* ------------------------------------------------------ */}
                        {/*  Add Request Search Bar                                */}
                        {/* ------------------------------------------------------ */}
                        
                        <div className='relative my-[10px]'>
    
                            {/* Search Bar */}
                            <div className='flex flex-row items-center'>
    
                                {/* Search Input Field */}
                                <input
                                    className={
                                        requestData?.addRequestSearchResults?.length > 0 ?
                                        'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] focus:outline-none'
                                        :
                                        'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] focus:outline-none'
                                    }
                                    type='text'
                                    placeholder='Search existing requests to add to agreement'
                                    autoComplete='no'
                                    onChange={(evt) => {
    
                                        const value = evt.target.value;
                                        setRequestData({  'addRequestsSearchInput': value });
    
                                        // Hide search
                                        if (value.length === 0) {
    
                                            return setRequestData({
                                              'addRequestsSearchInput': '',
                                              'addRequestSearchResults': []
                                            })
                      
                                        }
    
                                        // Trigger search
                                        handleAllRequestsSearch(value);
    
                                    }}
                                    value={requestData?.addRequestsSearchInput}
                                ></input>
    
                                {/* Clear Search Button */}
                                {
                                    requestData?.addRequestSearchResults?.length > 0 &&
                                    <div className='animate-fadein  bg-[#F1F1F1] h-[45px] p-[15px] shadow-md border border-solid border-[#080808] border-l-[none] rounded-tl-none rounded-br-none rounded-tr-[5px] rounded-bl-none text-center align-center cursor-pointer'>
                                        <img className='self-center' src={ClearSearch} alt='ClearSearch' onClick={() => setRequestData({
                                            'addRequestsSearchInput': '',
                                            'addRequestSearchResults': []
                                        })}></img>
                                    </div>
                                }
    
                            </div>
    
                            {/* Search Results */}
                            {
                                requestData?.addRequestSearchResults?.length > 0 &&
                                <div className='absolute z-10 max-h-[350px] w-full overflow-y-auto bg-white shadow-lg border-[1px] border-[solid] border-[#D2D2D2]'>
                                    {
                                        requestData?.addRequestSearchResults?.map((request, index) => (
                                        <div key={index} className='border-b border-b-[#E1E1E1] last:border-0 hover:bg-[#F0F0F0]'>
    
                                            {/* Request Detail */}
                                            {
                                                // Check if the request has already been linked
                                                requestData?.linkedRequests?.filter(object => object.requestid === request.requestid)?.length > 0 ?
    
                                                // Disabled State
                                                (
                                                    <Tooltip
                                                        message={`This request has already been linked to this ${document?.agreementid !== undefined ? 'agreement' : 'application'} `}
                                                        children={
                                                            <div className='w-full flex flex-row justify-between py-[10px] px-[15px]'>
                                                                <div className='flex flex-col'>
                                                                    <p className='m-0 font-medium text-[14px] text-[#C4C4C4] uppercase'>
                                                                        {request.requestid}
                                                                    </p>
                                                                    <p className='m-0 text-[14px] text-[#C4C4C4]'>
                                                                        {request?.taskname}
                                                                    </p>
                                                                </div>
            
                                                                <img className='w-[28px]' src={AddDisabled} alt='Add'></img>
                                                            </div>
                                                        }
                                                    ></Tooltip>
    
                                                ) 
                                                : 
    
                                                // Active State
                                                (
                                                    <div className='flex flex-row justify-between py-[10px] px-[15px]'>
                                                        <div className='flex flex-col'>
                                                            <p className='m-0 font-medium text-[14px] text-[#424242] disabled:text-[#C4C4C4] uppercase'>
                                                                {request.requestid}
                                                            </p>
                                                            <p className='m-0 text-[14px] text-[#A0A0A0] disabled:text-[#C4C4C4]'>
                                                                {request?.taskname}
                                                            </p>
                                                        </div>
                                                        
                                                        {/* Add Button */}
                                                        <img className='cursor-pointer w-[28px]' src={Add} alt='Add' onClick={() => setRequestData(
                                                            {
                                                                'linkedRequests': [...requestData?.linkedRequests, request],
                                                                'filteredRequests': [...requestData?.filteredRequests, request],
                                                                'addRequestsSearchInput': '',
                                                                'addRequestSearchResults': [],
                                                                'addedRequests': [...requestData?.addedRequests, request]
                                                            }
                                                        )}></img>
    
                                                    </div>
                                                )
                                            }
    
                                        </div>
                                        ))
                                    }
    
                                    {/* Total Search Results */}
                                    <div className='text-sm py-[10px] px-[15px]'>
                                        Found {requestData?.addRequestSearchResults?.length} result(s).
                                    </div>
    
                                </div>
                            }
    
                        </div>
    
                    </div>
    
                    {/* ------------------------------------------------------ */}
                    {/*  Body Container                                        */}
                    {/* ------------------------------------------------------ */}
    
                    <div className='flex flex-col justify-between h-full overflow-hidden'>
    
                        {/* ------------------------------------------------------ */}
                        {/*  Table Container                                       */}
                        {/* ------------------------------------------------------ */}
    
                        <div className='Table-Container overflow-hidden'>
    
                            {/* ======== Sorting and Filters ========== */}
                            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>
                                
                                <label className='font-semibold self-center'>
                                    Total: {requestData?.filteredRequests?.length} of {requestData?.linkedRequests?.length}
                                </label>
    
                                <div className='flex flex-row gap-2'>
                                    
                                    {/* ======== Search Bar ======== */}
                                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[400px]'>
                                        <img className='ml-[10px]' src={IconSearch} alt='search-icon'></img>
                                        <label>
                                            <input
                                                className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                                type='text'
                                                placeholder='Search linked requests'
                                                autoComplete='no'
                                                onChange={(e) => handleTableSearch(e.target.value)}
                                                value={requestData?.tableSearchInput}
                                            ></input>
                                        </label>
                                    </div>
    
                                    {/* Download Report */}
                                    <ExportToCSV
                                        filename={`${document.taskname}-Tasks-${Date.now()}`}
                                        data={requestData?.filteredRequests}
                                    ></ExportToCSV>
                                </div>
    
                            </div>
    
                            {/* ============= Table ============== */}
                            <table className='w-full max-h-96 overflow-y-scroll'>
                                <thead>
                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2'>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Request ID</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Type</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Created By</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Status</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // No tasks found
                                        requestData?.filteredRequests.length === 0 ?
                                            (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='text-center p-[2%]'>
                                                            No requests found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                            // Map in each task
                                            requestData?.filteredRequests?.map((doc, index) => (
                                                <tr key={index} className='border-b-[#C8C8C9] bg-[#FFFFFF] border-solid border-b last:border-0'>
    
                                                    {/* Request ID */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden uppercase'>
                                                        {doc?.requestid}
                                                    </td>
    
                                                    {/* Task Type */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        {doc?.tasktype}
                                                    </td>
    
                                                    {/* Created By */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <b className='font-medium text-[#424242]'>{doc?.createdby?.givenname} {doc?.createdby?.surname}</b>
                                                        <br></br>
                                                        <label className='text-sm'>{doc?.createdby?.email}</label>
                                                    </td>
    
                                                    {/* Status Label */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <StatusLabel status={doc?.status}></StatusLabel>
                                                    </td>
                                                    
                                                    {/* Remove Icon */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <div className='Cancel-Icon cursor-pointer min-w-[32px]' onClick={() =>  
    
                                                            setRequestData(
                                                                {
                                                                    'linkedRequests': requestData?.linkedRequests?.filter((object) => object !== doc),
                                                                    'filteredRequests': requestData?.linkedRequests?.filter((object) => object !== doc),
                                                                    'removedRequests': [...requestData?.removedRequests, doc]
                                                                }
                                                            )
    
                                                        }></div>
                                                    </td>
    
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
    
                        {/* ------------------------------------------------------ */}
                        {/*  Buttons Container                                     */}
                        {/* ------------------------------------------------------ */}
    
                        <div className='flex flex-row my-3 gap-2'>
    
                            {/* Submit */}
                            <button className='Primary-Button' disabled={false} type='submit' value='submit' onClick={() => handleSubmit()}>
                                Save
                            </button>
    
                            {/* Cancel */}
                            <button className='Secondary-Button' onClick={() => {
    
                                setRequestData({
                                    'allRequests': [],
                                    'linkedRequests': [],
                                    'filteredRequests': [],
                                    'addedRequests': [],
                                    'removedRequests': [],
                        
                                    // Add Requests Search
                                    'addRequestsSearchInput': '',
                                    'addRequestSearchResults': [],
                        
                                    // Table Search
                                    'tableSearchInput': ''
                                });
    
                                setDocument(undefined);
                                setPaneStatus('pending');

                            }}>
                                Cancel
                            </button>
                            
                        </div>
    
                    </div>
    
                </dialog >
            </div>
        )
    }

}
