//================================================================
//  Tab: Applications
//================================================================

//  Purpose: This tab displays all 'Applications' with the status of 'Active'

//  Example:
//    <Applications></Applications>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Contexts
import { SetSortFilters } from '../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../Components/PageComponent/PageComponent';
import ExportToCSV from '../../../Components/ExportToCSV/ExportToCSV';
import StatusLabel from '../../../Components/StatusLabel/StatusLabel';
import AddApplication from './SidePanes/AddApplication';
import LabelList from '../../../Components/LabelList/LabelList';
import UserPane from '../../../Components/UserPane/UserPane';
import TableHeader from '../../../Components/SortFilter/TableHeader';
import FilterTags from '../../../Components/SortFilter/FilterTags';
import FilterByPane from '../../../Components/SortFilter/FilterByPane';

//Functions
import QueryListener from '../../../Library/QueryListener';

//Images
import IconSearch from '../../../Components/Images/Icon_Search_Grey.svg';


export default function Applications() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const navigate = useNavigate();

  const setSortFilters = useContext(SetSortFilters);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState('pending');

  // Search Input
  const [searchInput, setSearchInput] = useState('');

  // Used to store all applications
  const [applications, setApplications] = useState([]);
  const [applicationsFiltered, setApplicationsFiltered] = useState([]);

  // Current Filter > 'Active', 'Divest', 'Dormant', 'Decommissioned' & 'All'
  const [currentFilter, setCurrentFilter] = useState('Active');

  // Open or Close the Application or Users side pane
  const [businessOwnerPane, setBusinessOwnerPane] = useState(undefined);
  const [ITOwnerPane, setITOwnerPane] = useState(undefined);
  const [subscriberPane, setSubscriberPane] = useState(undefined);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------ 

  // Handles search function
  function handleSearch(value) {

    setSearchInput(value);

    // Clear ALL filters & sorting
    if (value.length > 0 && value.length < 2) {

      setSortFilters({

        // Sorting
        'sortBy': '', // vendor.vendorname
        'sortDirection': '', // ascending & descending
        'sortDataType': '', // '' or 'number'

        // Filtering (Map of objects)
        'filters': {},

        // Sidepane for selecting filterby content
        'filterByPane': {
          'columnname': '', // Vendor Name
          'columnid': '', // vendor.vendorname
        }

      });

    }

    // Reset the Filter
    if (value.length === 0) {

      setApplicationsFiltered(applications);

    };

    // Search Filter
    //  1. applicationname
    //  2. vendor.vendorname
    //  3. businessowner
    //  4. itowner
    //  5. subscribed
    const searchResults = applications.filter((object) =>
      object?.applicationname?.toLowerCase().includes(value.toLowerCase()) ||
      object?.vendor?.vendorname?.toLowerCase().includes(value.toLowerCase()) ||
      object?.businessowner?.toString().toLowerCase().includes(value.toLowerCase()) ||
      object?.itowner?.toString().toLowerCase().includes(value.toLowerCase()) ||
      object?.subscribed?.toString().toLowerCase().includes(value.toLowerCase())
    );

    // Save the filtered search results to the useState
    setApplicationsFiltered(searchResults);

  };

  // Create data format for CSV Export
  function getDataObject(applications) {
    const data = [];

    applications.forEach((app) => {

      data.push({
        'Application Name': app.applicationname,
        'Vendor': app.vendorname,
        'Business Owner': app.businessowner,
        'IT Owner': app.itowner,
        'Subscribers': app.subscribed,
        'Used By': app.usedby,
        'Supported By': app.usedby,
        'BCP': app.bcp,
        'Managed by IT': app.itmanaged,
        'Commercially Managed by IT': app.itcommerciallymanaged,
        'Budgeted by IT': app.itbudgeted,
        'PoA Number': app.agreements?.map(agr => agr?.poanumber),
        'Status': app.applicationstatus,
      });
    })

    return data;
  }

  // Create data format for CSV Export for the Businesss
  function getBusinessDataObject(applications) {
    const data = [];

    applications.forEach((app) => {

      data.push({
        'Application Name': app.applicationname,
        'Description': app.applicationdescription,
        'Service Owner': app.businessowner,
        'Status': app.applicationstatus,
      });
    })

    return data;
  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all applications with the status of 'Active'
  useEffect(() => {

    if (currentFilter === undefined) return;

    function onLoad(documents) {

      setApplications(documents);
      setApplicationsFiltered(documents);

      setPageStatus('onload');

    }

    function onChange(documents) {

      setApplications(documents);

    }

    function onError(error) {

      console.log(error);

      setPageStatus('error-fatal');

    }

    const unsubscribe = QueryListener(
      'applications',
      currentFilter === 'All' ? [] : [['applicationstatus', '==', currentFilter]],
      onLoad,
      onChange,
      onError,
      ['applicationname', 'asc']
    );

    return () => {

      unsubscribe();

    };

    // eslint-disable-next-line
  }, [currentFilter]);

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (

    <PageComponent
      status={pageStatus}
      body={

        <div className='flex flex-col px-[15px] gap-[20px] w-[calc(100vw-40px)] max-h-[calc(100vh-220px)] overflow-hidden'>

          {/* ----------------------------------- */}
          {/*  Search & Filters                   */}
          {/* ----------------------------------- */}

          <div className='flex flex-row justify-between items-center gap-3'>

            <div className='flex flex-row items-center gap-2'>

              {/* Active */}
              <button className={currentFilter === 'Active' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Active')}>
                Active
              </button>

              {/* Divest */}
              <button className={currentFilter === 'Divest' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Divest')}>
                Divest
              </button>

              {/* Dormant */}
              <button className={currentFilter === 'Dormant' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Dormant')}>
                Dormant
              </button>

              {/* Archived */}
              <button className={currentFilter === 'Archived' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Archived')}>
                Archived
              </button>

              {/* Decommissioned */}
              <button className={currentFilter === 'Decommissioned' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('Decommissioned')}>
                Decommissioned
              </button>

              {/* All */}
              <button className={currentFilter === 'All' ? 'Filter-Primary' : 'Filter-Secondary'} onClick={() => setCurrentFilter('All')}>
                All
              </button>

            </div>

            <div className='flex flex-row items-center gap-2'>

              {/* Search Bar */}
              <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#D8D8D8] w-[300px]'>
                <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                <label htmlFor='searchInput'>
                  <input
                    id='searchInput'
                    className='border-none h-[40px] p-0 m-0 outline-none'
                    type='text'
                    placeholder='Search'
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchInput}
                  ></input>
                </label>
              </div>

              {/* Add Application */}
              <AddApplication></AddApplication>

              {/* Download IT Report */}
              <ExportToCSV
                filename={`applications-${Date.now()}`}
                data={getDataObject(applicationsFiltered)}
              ></ExportToCSV>

              {/* Download Business Report */}
              <ExportToCSV
                filename={`applications-business-view-${Date.now()}`}
                data={getBusinessDataObject(applicationsFiltered)}
                title={'Business Export'}
              ></ExportToCSV>

            </div>
          </div>

          {/* ----------------------------------- */}
          {/*  Table Container                    */}
          {/* ----------------------------------- */}

          <FilterTags
            tableArray={applications}
            setFilteredTableArray={setApplicationsFiltered}
          ></FilterTags>

          <div className='Table-Container flex flex-col w-full h-full overflow-hidden'>

            {/* ======== Table Filters ========== */}
            <div className='flex flex-row justify-between py-[15px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

              <label className='font-semibold self-center'>
                Total: {applicationsFiltered.length} of {applications.length}
              </label>

            </div>

            {/* Makes the table scrollable */}
            <div className='w-full overflow-auto'>

              <FilterByPane
                tableArray={applications}
              ></FilterByPane>

              <table className='w-full min-h-[350px]'>
                <thead>
                  <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b z-10 box-border overflow-visible'>

                    {/* Application Name (Sort/Filter) */}
                    <TableHeader
                      column='Application Name'
                      styleInput={{ minWidth: '120px' }}
                      objectKey='applicationname'
                      sticky={true}
                    ></TableHeader>

                    {/* Description */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-wrap'>Description</th>

                    {/* Vendor (Sort/Filter) */}
                    <TableHeader
                      column='Vendor'
                      objectKey='vendor.vendorname'
                    ></TableHeader>

                    {/* Business Owner */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Business Owner</th>

                    {/* IT Owner */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>IT Owner</th>

                    {/* Subscribers */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Subscribers</th>

                    {/* Used By */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Used By</th>

                    {/* Supported By */}
                    <TableHeader
                      column='Supported By'
                      objectKey='supportedby'
                    ></TableHeader>

                    {/* BCP */}
                    <TableHeader
                      column='BCP'
                      objectKey='bcp'
                    ></TableHeader>

                    {/* Managed by IT */}
                    <TableHeader
                      column='Managed by IT'
                      objectKey='itmanaged'
                    ></TableHeader>

                    {/* Commercially Managed by IT */}
                    <TableHeader
                      column='Commercially Managed by IT'
                      objectKey='itcommerciallymanaged'
                    ></TableHeader>

                    {/* Budgeted by IT */}
                    <TableHeader
                      column='Budgeted by IT'
                      objectKey='itbudgeted'
                    ></TableHeader>

                    {/* PoA Number */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>
                      PoA Number(s)
                    </th>

                    {/* Status */}
                    <th className='py-[15px] px-[10px] font-medium whitespace-nowrap'>Status</th>

                  </tr>
                </thead>
                <tbody>
                  {

                    // No applications found
                    applicationsFiltered.length === 0 ?
                      (
                        <tr>
                          <td colSpan={14}>
                            <div className='text-center p-[2%]'>
                              No applications found.
                            </div>
                          </td>
                        </tr>
                      )
                      :
                      (
                        // Map in each commercial application
                        applicationsFiltered.map((application, index) => (

                          <tr key={index} className='border-b-[#D8D8D8] border-solid border-b hover:bg-[#F0F7F7]'>
                            
                            {/* Application Name */}
                            <td className='align-top py-[15px] px-[10px] left-0 drop-shadow-md bg-white sticky font-medium cursor-pointer hover:underline max-w-[30ch] whitespace-nowrap overflow-hidden text-ellipsis' onClick={() => navigate(`/inventory/application/${application.applicationid}?view=overview`)}>
                              {application?.applicationname}
                            </td>

                            {/* Description */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r max-w-[40ch] max-h-px overflow-hidden whitespace-wrap text-ellipsis'>
                              {application?.applicationdescription}
                            </td>

                            {/* Vendor Name */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application.vendor.vendorname}
                            </td>

                            {/* Business Owner (Editable) */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              <LabelList
                                objectList={application?.businessowner}
                                object={application}
                                index={`${index}-0`}
                                setSidePane={setBusinessOwnerPane}
                              ></LabelList>
                            </td>

                            {/* IT Owner (Editable) */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              <LabelList
                                objectList={application?.itowner}
                                object={application}
                                index={`${index}-1`}
                                setSidePane={setITOwnerPane}
                              ></LabelList>
                            </td>

                            {/* Subscribers (Editable) */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              <LabelList
                                objectList={application?.subscribed}
                                object={application}
                                index={`${index}-2`}
                                setSidePane={setSubscriberPane}
                              ></LabelList>
                            </td>

                            {/* Used By */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              <ul className='list-none list-inside p-0'>
                                {
                                  application?.usedby?.length > 2 ? (

                                    <div>
                                      <div className='flex flex-col'>
                                        <label className='min-w-[10ch] overflow-hidden whitespace-nowrap text-ellipsis'>{application.usedby[0]}</label>
                                        <label className='min-w-[10ch] overflow-hidden whitespace-nowrap text-ellipsis'>{application.usedby[1]}</label>
                                      </div>

                                      <div>
                                        <label className='text-right font-semibold text-[#212529] float-right'>... 2 of {application.usedby.length}</label>
                                      </div>
                                    </div>

                                  ) : application?.usedby?.length > 0 ? (

                                    <div className='flex flex-col min-w-[10ch] overflow-hidden whitespace-nowrap text-ellipsis'>
                                      {
                                        application.usedby.map((obj, index) => (

                                          <label key={index} className='min-w-[10ch] overflow-hidden whitespace-nowrap text-ellipsis'> {obj} </label>

                                        ))
                                      }
                                    </div>
                                  ) :

                                    (
                                      <div className='flex flex-row justify-between'>
                                        <label className='bg-[#F5F5F5] round py-[6px] px-[12px] mx-auto'>N/A</label>
                                      </div>
                                    )

                                }
                              </ul>
                            </td>

                            {/* Supported By */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application?.supportedby}
                            </td>

                            {/* BCP */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application?.bcp}
                            </td>

                            {/* Managed by IT */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application?.itmanaged}
                            </td>

                            {/* Commercially Managed by IT */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application?.itcommerciallymanaged}
                            </td>

                            {/* Budgeted by IT */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {application?.itbudgeted}
                            </td>

                            {/* PoA Number(s) */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              {
                                application?.agreements?.length > 2 ? 
                                (

                                  <div>
                                    <div className='flex flex-col'>
                                      <label className='overflow-hidden whitespace-nowrap text-ellipsis'>{application.agreements[0].poanumber}</label>
                                      <label className='overflow-hidden whitespace-nowrap text-ellipsis'>{application.agreements[1].poanumber}</label>
                                    </div>

                                    <div>
                                      <label className='text-right font-semibold text-[#212529] float-right'>... 2 of {application.agreements.length}</label>
                                    </div>
                                  </div>

                                ) 
                                : application?.agreements?.length > 0 ? 
                                (

                                  <div className='flex flex-col'>
                                    {
                                      application.agreements.map((obj, index) => (

                                        <label key={index} className='overflow-hidden whitespace-nowrap text-ellipsis'> {obj.poanumber} </label>
                                      ))
                                    }

                                  </div>

                                ) 
                                :
                                (

                                  <div className='flex flex-row justify-between'>
                                    <label className='bg-[#F5F5F5] round py-[6px] px-[12px] mx-auto'>N/A</label>
                                  </div>

                                )

                              }
                            </td>

                            {/* Status */}
                            <td className='align-top py-[15px] px-[10px] border-solid border-r'>
                              <StatusLabel status={application?.applicationstatus}></StatusLabel>
                            </td>

                          </tr>

                        ))
                      )
                  }
                </tbody>
              </table>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Side Panes                         */}
          {/* ----------------------------------- */}

          <UserPane
            title={subscriberPane?.applicationname}
            vendorname={subscriberPane?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={subscriberPane?.applicationid}
            fieldPath={'subscribed'}
            defaultUsers={subscriberPane?.subscribed}
            paneOpen={subscriberPane}
            setPaneOpen={setSubscriberPane}
          ></UserPane>

          <UserPane
            title={businessOwnerPane?.applicationname}
            vendorname={businessOwnerPane?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={businessOwnerPane?.applicationid}
            fieldPath={'businessowner'}
            defaultUsers={businessOwnerPane?.businessowner}
            paneOpen={businessOwnerPane}
            setPaneOpen={setBusinessOwnerPane}
          ></UserPane>

          <UserPane
            title={ITOwnerPane?.applicationname}
            vendorname={ITOwnerPane?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={ITOwnerPane?.applicationid}
            fieldPath={'itowner'}
            defaultUsers={ITOwnerPane?.itowner}
            paneOpen={ITOwnerPane}
            setPaneOpen={setITOwnerPane}
          ></UserPane>

        </div>

      }

    ></PageComponent>
  )
}
