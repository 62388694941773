//================================================================
//  Tab: Overview
//================================================================

//  Purpose: This tab displays all 'Overview' of an Application

//  Example:
//    <Overview></Overview>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import PageComponent from '../../../../../Components/PageComponent/PageComponent';
import UserPane from '../../../../../Components/UserPane/UserPane';
import LiveField from '../../../../../Components/LiveField/LiveField';
import AgreementsPane from '../../../../../Components/AgreementsPane/AgreementsPane';
import LabelList from '../../../../../Components/LabelList/LabelList';
import TasksPane from '../../../../../Components/TasksPane/TasksPane';

//Functions
import DocumentListener from '../../../../../Library/DocumentListener';

//Images
import EditIcon from '../../../../../Components/Images/Icon_Edit_Teal.svg';
import People from '../../../../../Components/Images/Icon_People_Grey.svg';


export default function Overview() {

  //------------------------------------------------------
  //  useContext & React Router
  //------------------------------------------------------

  const getUser = useContext(GetUser);
  const params = useParams();

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to save page status > 'pending', 'onload', 'error-invalid', 'error-fatal'
  const [pageStatus, setPageStatus] = useState();

  // Holds the application document from 
  const [applicationDoc, setApplicationDoc] = useState();

  // Open & Close the side panes
  const [openSubscribersPane, setOpenSubscribersPane] = useState(false);
  const [businessOwnerPane, setBusinessOwnerPane] = useState(false);
  const [ITOwnerPane, setITOwnerPane] = useState(false);
  const [agreementPane, setAgreementPane] = useState(undefined);
  const [unsubscribedPane, setUnsubscribedPane] = useState(false);
  const [taskPane, setTaskPane] = useState(undefined);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Query Listener
  // - Get all Overview with the status of 'Active'
  useEffect(() => {

    const applicationId = params.id;

    if (applicationId === '') return;
    if (applicationId === null) return;

    function onLoadChange(document) {

      setApplicationDoc(document);

    }

    function onError(error) {

      console.log(error);
      setPageStatus('error-fatal');

    }

    const unsubscribe = DocumentListener('applications', applicationId, onLoadChange, onLoadChange, onError);

    return () => {
      unsubscribe();
    };

    // eslint-disable-next-line
  }, [getUser])


  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  return (
    <PageComponent
      status={pageStatus}
      body={
        <div className='px-[20px]'>

          <div className='flex justify-between'>

            {/* ----------------------------------- */}
            {/*  Edit Form                          */}
            {/* ----------------------------------- */}

            <div className='flex flex-col w-[50%]'>
              
              {/* Application Description */}
              <LiveField
                type='multiline'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'applicationdescription'}
                label={<label className='font-medium'>Application Description</label>}
                defaultValue={applicationDoc?.applicationdescription}
                placeholder={'The description of the application'}
              ></LiveField>

              {/* Application Status */}
              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'applicationstatus'}
                label={<label className='font-medium'>Application Status</label>}
                defaultValue={applicationDoc?.applicationstatus}
                options={['Active', 'Divest', 'Dormant', 'Archived', 'Decommissioned']}
              ></LiveField>

              {/* Used By */}
              <LiveField
                type='multiselect'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'usedby'}
                label={<label className='font-medium'>Used By</label>}
                defaultValue={applicationDoc?.usedby}
                options={[
                  'AU - Investment Management',
                  'AU - Construction',
                  'Construction',
                  'AU - Development',
                  'AU - Communities',
                  'AU - Capella',
                  'AU - Defence',
                  'Defence',
                  'Operations',
                  'Group - CA&M',
                  'Group - EH&S',
                  'Group - Risk',
                  'Group - Finance',
                  'Group - Legal',
                  'Group - Treasury',
                  'Group - Sustainability',
                  'Group - Internal Audit',
                  'Group - IT',
                  'Group - People and Culture',
                  'Podium',
                  'AUST',
                  'AMER',
                  'EUR/UK',
                  'ASIA',
                ]}
                disabled={true} // Managed automatically by ITM workflow DO NOT CHANGE!
              ></LiveField>

              {/* Capability Family */}
              <LiveField
                type='multiselect'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'capabilityfamily'}
                label={<label className='font-medium'>Capability Family</label>}
                defaultValue={applicationDoc?.capabilityfamily}
                options={[
                  'Project collaboration',
                  'Commercial Mngt',
                  'Design Mngt',
                  'Planning & Logistics',
                  'Cost Planning & Estimating',
                  'Project controls / Site mngt',
                  'Governance',
                  'General productivity',]}
              ></LiveField>

              {/* Capability Level 3 */}
              <LiveField
                type='multiline'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'capabilitylevel3'}
                label={<label className='font-medium'>Capability Level 3</label>}
                defaultValue={applicationDoc?.capabilitylevel3}
                placeholder={'This field is only to be used for construction please speak to Khuong if you have any questions.'}
              ></LiveField>

              {/* Managed by IT */}
              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'itmanaged'}
                label={<label className='font-medium'>Managed by IT</label>}
                defaultValue={applicationDoc?.itmanaged}
                options={[
                  'Yes',
                  'No',
                ]}
              ></LiveField>

              {/* Commercially Managed by IT */}
              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'itcommerciallymanaged'}
                label={<label className='font-medium'>Commercially Managed by IT</label>}
                defaultValue={applicationDoc?.itcommerciallymanaged}
                options={[
                  'Yes',
                  'No',
                ]}
              ></LiveField>

              {/* Budgeted by IT */}
              <LiveField
                type='choice'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'itbudgeted'}
                label={<label className='font-medium'>Budgeted by IT</label>}
                defaultValue={applicationDoc?.itbudgeted}
                options={[
                  'Yes',
                  'No',
                ]}
              ></LiveField>

              {/* Decommissioned Date */}
              <LiveField
                type='timestamp'
                collectionid='applications'
                documentid={applicationDoc?.applicationid}
                fieldpath={'decommissionedate'}
                label={<label className='font-medium'>Decommissioned Date</label>}
                defaultValue={applicationDoc?.decommissionedate}
              ></LiveField>

              {/* Last Modified By */}
              <LiveField
                collectionid={'applications'}
                documentid={applicationDoc?.agreementid}
                fieldpath={'lastmodifiedby'}
                label={<label className='font-medium'>Last Modified By</label>}
                defaultValue={applicationDoc?.lastmodifiedby?.email}
                type={'text'}
                disabled={true}
              ></LiveField>  

              {/* Last Modified Date */}
              <LiveField
                collectionid={'applications'}
                documentid={applicationDoc?.agreementid}
                fieldpath={'lastmodifieddate'}
                label={<label className='font-medium'>Last Modified Date</label>}
                defaultValue={applicationDoc?.lastmodifieddate}
                type={'timestamp'}
                disabled={true}
              ></LiveField>  

            </div>

            {/* ----------------------------------- */}
            {/*  Tiles                              */}
            {/* ----------------------------------- */}

            <div className='flex flex-col gap-3'>

              {/* Subscribers */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Subscribers</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setOpenSubscribersPane(true)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <label className='flex flex-row gap-2 w-fit py-1 px-2 bg-[#F5F5F5] text-[#424242] rounded-md font-medium'>
                    <img src={People} alt='people'></img>
                    {applicationDoc?.subscribed?.length} subscribed
                  </label>
                </div>

              </div>

              {/* Business Owner */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Business Owner</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setBusinessOwnerPane(true)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <LabelList objectList={applicationDoc?.businessowner} object={applicationDoc} index={0}></LabelList>
                </div>

              </div>

              {/* IT Owner */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> IT Owner</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setITOwnerPane(true)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <LabelList objectList={applicationDoc?.itowner} object={applicationDoc} index={0}></LabelList>
                </div>

              </div>

              {/* Agreements */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Agreements</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setAgreementPane(applicationDoc)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <LabelList objectList={applicationDoc?.agreements.map((agreement) => agreement?.poanumber)} object={applicationDoc} index={0}></LabelList>
                </div>

              </div>

              {/* Linked Requests */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Linked Requests</label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setTaskPane(applicationDoc)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='min-h-[40px] max-h-[160px] overflow-y-auto'>
                  <label className='py-[15px] px-[20px]'>{applicationDoc?.requestid.filter((value) => value !== '' && value !== null).length} linked requests</label>
                </div>

              </div>
              
              {/* Unsubscribed */}
              <div className='border-solid border-1 rounded-lg shadow-md w-[300px]'>

                <div className='flex flex-row justify-between items-center py-[12px] px-[20px]'>
                  <label className='font-medium'> Unsubscribed </label>
                  <img className='min-w-[20px] cursor-pointer' src={EditIcon} alt='Edit-Icon' onClick={() => setUnsubscribedPane(true)}></img>
                </div>

                <hr className='m-0 border-1 border-[#424242]'></hr>

                <div className='py-[15px] px-[20px]'>
                  <label className='flex flex-row gap-2 w-fit py-1 px-2 bg-[#F5F5F5] text-[#424242] rounded-md font-medium'>
                    <img src={People} alt='people'></img>
                    {applicationDoc?.unsubscribed?.length} unsubscribed
                  </label>
                </div>

              </div>

            </div>

          </div>

          {/* ----------------------------------- */}
          {/*  Side Panes                         */}
          {/* ----------------------------------- */}

          <UserPane
            title={`Subscribers | ${applicationDoc?.applicationname}`}
            vendorname={applicationDoc?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={applicationDoc?.applicationid}
            fieldPath={'subscribed'}
            defaultUsers={applicationDoc?.subscribed}
            paneOpen={openSubscribersPane}
            setPaneOpen={setOpenSubscribersPane}
          ></UserPane>

          <UserPane
            title={`Business Owners | ${applicationDoc?.applicationname}`}
            vendorname={applicationDoc?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={applicationDoc?.applicationid}
            fieldPath={'businessowner'}
            defaultUsers={applicationDoc?.businessowner}
            paneOpen={businessOwnerPane}
            setPaneOpen={setBusinessOwnerPane}
          ></UserPane>

          <UserPane
            title={`IT Owners | ${applicationDoc?.applicationname}`}
            vendorname={applicationDoc?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={applicationDoc?.applicationid}
            fieldPath={'itowner'}
            defaultUsers={applicationDoc?.itowner}
            paneOpen={ITOwnerPane}
            setPaneOpen={setITOwnerPane}
          ></UserPane>

          <AgreementsPane 
            agreements={agreementPane}
            setAgreementPane={setAgreementPane}
          ></AgreementsPane>

          <TasksPane
            document={taskPane}
            setDocument={setTaskPane}
          ></TasksPane>

          <UserPane
            title={`Unsubscribed | ${applicationDoc?.applicationname}`}
            vendorname={applicationDoc?.vendor?.vendorname}
            collectionid={'applications'}
            documentid={applicationDoc?.applicationid}
            fieldPath={'unsubscribed'}
            defaultUsers={applicationDoc?.unsubscribed}
            paneOpen={unsubscribedPane}
            setPaneOpen={setUnsubscribedPane}
          ></UserPane>

        </div>
      }
    ></PageComponent>
  )
}
