//================================================================
//  Component: Agreement Details
//================================================================

//  Purpose: See list of Applications in an Agreement

//  Properties:
//    - agreement = {object, documentid}

//  AgreementDetails:
//    <AgreementDetails
//      agreement={agreement}
//    ></AgreementDetails>    

//================================================================


//Libraries
import React, { useState, useEffect, useContext } from 'react';

//Contexts
import { SetToast } from '../../../../Library/GlobalContexts';

//Components
import StatusLabel from '../../../../Components/StatusLabel/StatusLabel';
import ExportToCSV from '../../../../Components/ExportToCSV/ExportToCSV';

//Functions
import GetCollection from '../../../../Library/GetCollection';
import WriteDocument from '../../../../Library/WriteDocument';

//Images
import IconSearch from '../../../../Components/Images/Icon_Search_Grey.svg';
import InfoIcon from '../../../../Components/Images/Icon_Info_Blue.svg';


export default function EditApplicationsInAgreement({
    agreements,
    setAgreementPane,
    applications
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Set List of Applications in the Agreement
    const [applicationList, setApplicationList] = useState([]);
    const [applicationsFiltered, setApplicationsFiltered] = useState([]);
    const [allApplications, setAllApplications] = useState([]);

    // Search Values - Application Search
    const [searchDropdown, setSearchDropdown] = useState(false);
    const [searchApplicationsFiltered, setSearchApplicationsFiltered] = useState([]);

    // Search Values - Agreement Search
    const [searchInput, setSearchInput] = useState('');
    const [searchApplications, setSearchApplications] = useState('');

    // List of applications to remove from agreement
    const [toRemoveList, setToRemoveList] = useState([]);


    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    const searchForApplication = (value) => {

        // Turn Dropdown on if field is not empty
        setSearchDropdown(value !== '');

        setSearchApplications(value);

        const searchResults = allApplications.filter((object) =>

            object?.applicationname?.toLowerCase().includes(value.toLowerCase()) ||
            object?.applicationid?.toLowerCase().includes(value.toLowerCase())

        );

        setSearchApplicationsFiltered(searchResults);

    };

    const handleTableSearch = (value) => {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {
            setApplicationsFiltered(applications);
        };

        // Search Filter
        //  1. Application Name
        //  2. Application ID
        const searchResults = applicationList.filter((object) =>
            object?.name?.toLowerCase().includes(value.toLowerCase()) ||
            object?.vendor?.toLowerCase().includes(value.toLowerCase())
        );

        setApplicationsFiltered(searchResults);
    
    }

    // Adds application to agreement
    const addApplication = (app) => {

        const tempAppList = applicationList;

        tempAppList.push({
            'name': app?.applicationname,
            'id': app?.applicationid,
            'vendor': app?.vendor?.vendorname,
            'businessowner': app?.businessowner,
            'itowner': app?.itowner,
            'status': app?.applicationstatus,
            'agreements': app?.agreements,
        });

        setApplicationList(tempAppList);
        setApplicationsFiltered(tempAppList);

        setSearchDropdown(false);

    }

    // Deletes application from agreement
    const deleteApplication = (app) => {

        setToRemoveList([...toRemoveList, app]);

        const tempAppList = applicationList.filter((object) =>
            object !== app
        );

        setApplicationList(tempAppList);
        setApplicationsFiltered(tempAppList);
    }

    const handleSubmit = () => {

        // Open the toast
        setToast({
            'type': 'pending',
            'message': 'Saving changes',
        });

        const docPromises = [];

        const docObject = {
            'agreementid': agreements?.agreementid,
            'poanumber': agreements?.poanumber
        }

        // Write to FS for each application to add
        applicationList.forEach((application) => {

            let agreementsList = [];

            // Create a list of agreements to write to the Application document
            if (application?.agreements) agreementsList = application?.agreements;

            agreementsList.push(docObject);

            docPromises.push(WriteDocument('applications', application?.id, { 'agreements': agreementsList }, true));
        })

        // Write to FS for each application to remove
        toRemoveList.forEach((application) => {

            const agreementsList = application?.agreements?.filter(agr => agr.agreementid !== docObject.agreementid);

            console.log('Writing to FS with:', agreementsList);
            docPromises.push(WriteDocument('applications', application?.id, { 'agreements': agreementsList }, true));


        })

        Promise.all(docPromises)
            .then(() => {

                // Show Success Toast
                setToast(
                    {
                        'type': 'success',
                        'message': 'Agreement saved'
                    }
                );

            })
            .catch((error) => {

                console.log('error', error);

                // Set error on toast
                setToast({
                    'type': 'error',
                    'message': 'Failed to save agreement',
                });


            });

        //Reset input fields and close pane.
        setSearchApplications('');
        setSearchInput('');
        setAgreementPane(undefined);

    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    useEffect(() => {

        // Close the pane if there are issues
        if (agreements === undefined) return;

        GetCollection('applications')
        .then((document) => {

            setAllApplications(document)

            // Use Applications document to get Agreement applications
            const tempAppList = [];

            document?.forEach((object) => {

                const filteredApps = object?.agreements?.filter((obj) => obj.agreements?.agreementid === agreements?.agreementid);
                tempAppList.push(...filteredApps);

            });


            setApplicationList(tempAppList);
            setApplicationsFiltered(tempAppList);

            // console.log('all applications:', allApplications);
            // console.log('application list', applicationList);
            // console.log('applications filtered:', applicationsFiltered);

        })
        .catch((error) => {

            console.log('error', error);
            // TODO - Add toast for errors here

        });

        // eslint-disable-next-line
    }, [agreements]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (agreements === undefined) return null;

    return (

        <div className='Pane-Background'>

            <dialog className='Application-Details-Pane-Container flex flex-col justify-between'>

                <div className='flex flex-col gap-[15px] overflow-hidden'>

                    {/*  ========= Heading ========= */}
                    <div>
                        <h4>Agreement | {agreements.poanumber}</h4>
                        <p className='text-xl text-zinc-600'>{agreements.vendorname}</p>
                        <hr className='my-2'></hr>
                    </div>

                    {/* ======== Search Bar ======== */}
                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6]'>
                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                        <label htmlFor='searchInput'>
                            <input
                                id='searchInput'
                                className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                type='text'
                                placeholder='Search existing applications to add to agreement'
                                autoComplete='no'
                                onChange={(e) => searchForApplication(e.target.value)}
                                value={searchApplications}
                            ></input>

                            {searchDropdown && (

                                < div className={'absolute bg-[#FFFFFF] w-[82vw] left-[2vw] border-solid border-1 drop-shadow-lg z-10'}>
                                    <ul className='list-none list-inside p-0'>
                                        {searchApplicationsFiltered.slice(0, 5).map((app, index) => (
                                            <li
                                                key={index}
                                                className='hover:bg-slate-200 p-2 h-auto border-solid border-1 cursor-pointer font-semibold'
                                            >
                                                {
                                                    applicationList.some(object => object.applicationid === app.applicationid)
                                                        ? <div className='text-gray-400 leading-none'>
                                                            <p>{app.applicationname}</p>
                                                            <p className='font-light'>This application is already in this agreement.</p>
                                                        </div>
                                                        : <p onClick={() => { addApplication(app) }}
                                                        >{app.applicationname}</p>
                                                }
                                            </li>

                                        ))}
                                        <li className='px-2 font-light'>Showing {searchApplicationsFiltered.length > 5 ? '5 of ' + searchApplicationsFiltered.length : searchApplicationsFiltered.length} result{searchApplicationsFiltered.length === 1 ? '.' : 's.'}</li>
                                    </ul>
                                </div>

                            )}

                        </label>

                    </div>

                    {/* ======== Information Box ======== */}
                    <div className='Info-Box flex flex-row px-2'>
                        <img className='h-6 px-2 self-center' alt='Info-Icon' src={InfoIcon} />
                        <label className='p-1' >To add new applications to the inventory, click <a href='/inventory?view=applications' target='_blank'> here</a>.</label>
                    </div>

                    {/*  ========= Table ========= */}
                    <div className='Table-Container overflow-hidden'>

                        {/* ======== Sorting and Filters ========== */}
                        <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                            <label className='font-semibold self-center'>
                                Total: {applicationsFiltered.length} of {applicationList.length}
                            </label>

                            <div className='flex flex-row gap-2'>

                                {/* ======== Search Bar ======== */}
                                <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[400px]'>
                                    <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                    <label htmlFor='searchInput'>
                                        <input
                                            id='searchInput'
                                            className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                            type='text'
                                            placeholder='Search applications in this agreement'
                                            onChange={(e) => handleTableSearch(e.target.value)}
                                            autoComplete='no'
                                            value={searchInput}
                                        ></input>
                                    </label>
                                </div>

                                {/* Download Report */}
                                <ExportToCSV
                                    filename={`${agreements.vendorname}-Applications-${Date.now()}`}
                                    data={applicationsFiltered}
                                ></ExportToCSV>

                            </div>

                        </div>

                        <div className='overflow-y-scroll'>

                            <table className='w-full'>
                                <thead>
                                    <tr className='sticky top-0 drop-shadow-md bg-white border-b-[#D8D8D8] border-solid border-b-2'>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'>Application Name</th>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'>Vendor</th>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'>Business Owner</th>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'>IT Owner</th>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'>Status</th>
                                        <th className='py-[15px] px-[10px] font-semibold text-[#212529]'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // No applications found
                                        applicationsFiltered.length === 0 ?
                                            (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='text-center p-[2%]'>
                                                            No agreements found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) 
                                            :
                                            // Map in each registered application
                                            applicationsFiltered.map((application, index) => (
                                                <tr key={index} className='border-b-[#C8C8C9] bg-[#FFFFFF] border-solid border-b last:border-0'>
                                                    
                                                    {/* Application Name */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'>
                                                        {application?.applicationname}
                                                    </td>

                                                    {/* Vendor Name */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'>
                                                        {agreements?.vendorname}
                                                    </td>

                                                    {/* Business Owner */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'>
                                                        {
                                                            <ul className='list-none list-inside p-0'>
                                                                {
                                                                    application?.businessapprover?.map((emailaddress) => (
                                                                        <li className='max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis'>{emailaddress}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }
                                                    </td>

                                                    {/* IT Owner */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'>
                                                        <ul className='list-none list-inside p-0'>
                                                            {
                                                                application?.itowner?.map((emailaddress, index) => (

                                                                    <li key={index} className='max-w-[200px] whitespace-nowrap overflow-hidden text-ellipsis'>{emailaddress}</li>

                                                                ))
                                                            }

                                                        </ul>
                                                    </td>

                                                    {/* Status */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'>
                                                        <StatusLabel status={application?.applicationstatus}></StatusLabel>
                                                    </td>

                                                    {/* Delete Icon */}
                                                    <td className='py-[15px] px-[10px] max-w-[160px] overflow-hidden'> 
                                                        <div className='Cancel-Icon cursor-pointer min-w-[32px]' onClick={() => deleteApplication(application)}></div>
                                                    </td>

                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>

                        </div>


                    </div>

                </div>

                {/*  ========= Button Container ========= */}
                <div className='flex flex-row mt-3 gap-2' >

                    {/* Submit */}
                    <button className='Primary-Button' onClick={() => handleSubmit()}>Save</button>

                    {/* Cancel */}
                    <button className='Secondary-Button' onClick={() => setAgreementPane(undefined)}>Cancel</button>
                </div>

            </dialog >
        </div >

    )
}
