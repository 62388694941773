//================================================================
//  Component: Agreement Details
//================================================================

//  Purpose: See list of Applications in an Agreement

//  Properties:
//    - agreement = {object, documentid}
//    - setAgreementPane = {useState used to determine if the modal is open/closed}

//  AgreementsPane:
//    <AgreementsPane
//      agreement={agreement}
//      setAgreementPane={setAgreementPane}
//    ></AgreementsPane>    

//================================================================

//Libraries
import React, { useContext, useState, useEffect } from 'react';

//Contexts
import { SetToast } from '../../Library/GlobalContexts';

//Components
import Tooltip from '../Tooltip/Tooltip';
import StatusLabel from '../StatusLabel/StatusLabel';
import ExportToCSV from '../ExportToCSV/ExportToCSV';

//Functions
import GetCollection from '../../Library/GetCollection';
import WriteDocument from '../../Library/WriteDocument';

//Images
import IconSearch from '../Images/Icon_Search_Grey.svg';
import InfoIcon from '../Images/Icon_Info_Blue.svg';
import ClearSearch from '../Images/Icon_Clear_Grey.svg';
import Add from '../Images/Icon_Add_Teal.svg';
import AddDisabled from '../Images/Icon_AddDisabled_Teal.svg';


export default function AgreementsPane({
    agreements,
    setAgreementPane,
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const setToast = useContext(SetToast);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Set List of Applications in the Agreement
    const [agreementList, setAgreementList] = useState([]);
    const [agreementsFiltered, setAgreementsFiltered] = useState([]);
    const [allAgreements, setAllAgreements] = useState([]);

    // Used to determine if the agreements search dropdown is visible
    const [searchDropdown, setSearchDropdown] = useState(false);

    // Used to store the agreements search results
    const [searchAgreementsFiltered, setSearchAgreementsFiltered] = useState([]);
    
    // Used to store search input for agreements search
    const [searchAgreements, setSearchAgreements] = useState('');

    // Used to store search input for table search
    const [searchInput, setSearchInput] = useState('');

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Handles search to add an application
    const searchForApplication = (value) => {

        // Turn Dropdown on if field is not empty
        setSearchDropdown(value !== '');

        setSearchAgreements(value);

        const searchResults = allAgreements.filter((object) =>

            object?.poanumber?.toLowerCase().includes(value.toLowerCase())

        );

        setSearchAgreementsFiltered(searchResults);

    };

    // Handles Table Search 
    const handleTableSearch = (value) => {

        setSearchInput(value);

        // Reset the Filter
        if (value.length === 0) {
            setAgreementsFiltered(agreementList);
        };

        // Search Filter
        //  1. POA number
        const searchResults = agreementList.filter((object) =>
            object?.poanumber?.toLowerCase().includes(value.toLowerCase())
        );

        setAgreementsFiltered(searchResults);
    }

    // Adds application to agreement
    const addApplication = (app) => {

        const tempAppList = agreementList;

        tempAppList.push(app);

        setAgreementList(tempAppList);
        setAgreementsFiltered(tempAppList);
        setSearchAgreements('');
        setSearchAgreementsFiltered([]);
        setSearchDropdown(false);

        return;
    }

    // Deletes application from agreement
    const deleteApplication = (app) => {

        const tempAppList = agreementList.filter((object) =>
            object !== app
        );

        setAgreementList(tempAppList);
        setAgreementsFiltered(tempAppList);
        return;

    }

    // Submits changes to the table
    const handleSubmit = () => {

        const agreementsList = [];

        agreementList.forEach((object) => {
            agreementsList.push({
                'agreementid': object.agreementid,
                'poanumber': object.poanumber
            });
        })

        const appObject = {
            'agreements': agreementsList
        };

        WriteDocument('applications', agreements.applicationid, appObject, true)
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to save changes',
            });

        });

        //Reset input fields and close pane.
        setSearchAgreements('');
        setSearchInput('');
        setAgreementPane(undefined);
        setSearchAgreementsFiltered([]);

        return;
    }

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    useEffect(() => {

        // Close the pane if there are issues
        if (agreements === undefined) return;
        if (agreements?.agreements === undefined) return;

        GetCollection('agreements')
        .then((document) => {

            setAllAgreements(document)

            // Use Applications document to get Agreement applications
            const tempAppList = [];

            agreements.agreements.forEach(object => {

                const filteredApps = document.filter((app) => app.poanumber === object.poanumber);
                tempAppList.push(...filteredApps);

            });

            setAgreementList(tempAppList);
            setAgreementsFiltered(tempAppList);

        })
        .catch((error) => {

            console.log('error', error);

            // Set error on toast
            setToast({
                'type': 'error',
                'message': 'Failed to get agreements',
            });


        });


    }, [agreements, setToast]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    if (agreements === undefined) return null; // Can I delete this?

    return (
        <>

            {/* =========================================================== */}
            {/*  Agreement Details Pane                                     */}
            {/* =========================================================== */}

            <div className='Pane-Background'>
                <dialog className='Application-Details-Pane-Container flex flex-col gap-2 pb-0'>

                    {/* ------------------------------------------------------ */}
                    {/*  Header Container                                      */}
                    {/* ------------------------------------------------------ */}

                    <div className='flex flex-col'>

                        {/* ------------------------------------------------------ */}
                        {/*  Header                                                */}
                        {/* ------------------------------------------------------ */}

                        <div className='flex flex-col gap-1'>

                            {/* Title */}
                            <h5> Agreements | {agreements.applicationname} </h5>
                            <span className='text-[#424242] text-sm' hidden={agreements?.vendorname === undefined || agreements?.vendorname?.length === 0}>
                                {agreements.vendorname}
                            </span>

                            {/* Border - Bottom */}
                            <hr className='my-2'></hr>

                        </div>

                        {/* ------------------------------------------------------ */}
                        {/*  Add Application Search Bar                            */}
                        {/* ------------------------------------------------------ */}           

                        <div className='relative my-[10px]'>
                            
                            {/* Search Bar */}
                            <div className='flex flex-row items-center'>
                                
                                {/* Search Input Field */}
                                <input
                                    className={
                                        searchAgreementsFiltered?.length > 0 ?
                                        'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-tl-[5px] focus:outline-none'
                                        :
                                        'w-full h-[45px] px-[15px] py-[10px] bg-[white] border border-solid border-[#7c7c7c] rounded-[5px] focus:outline-none'
                                    }
                                    type='text'
                                    placeholder='Search existing agreements to add to application'
                                    autoComplete='no'
                                    onChange={(e) => searchForApplication(e.target.value)}
                                    value={searchAgreements}
                                ></input>
    
                                {/* Clear Search Button */}
                                {
                                    searchAgreementsFiltered?.length > 0 &&
                                    <div className='animate-fadein  bg-[#F1F1F1] h-[45px] p-[15px] shadow-md border border-solid border-[#080808] border-l-[none] rounded-tl-none rounded-br-none rounded-tr-[5px] rounded-bl-none text-center align-center cursor-pointer'>
                                        <img className='self-center' src={ClearSearch} alt='ClearSearch' onClick={() => {
                                            setSearchAgreementsFiltered([]);
                                            setSearchAgreements('');
                                        }}></img>
                                    </div>
                                }

                            </div>

                            {/* Search Results */}
                            {
                                searchDropdown &&
                                <div className='absolute z-10 max-h-[350px] w-11/12 overflow-y-auto bg-white shadow-lg border-[1px] border-[solid] border-[#D2D2D2]'>
                                    {
                                        searchAgreementsFiltered.slice(0, 5).map((app, index) => (
                                            <div key={index} className='border-b border-b-[#E1E1E1] last:border-0 hover:bg-[#F0F0F0]'>

                                                {
                                                    // Check to see if app is already linked
                                                    agreementsFiltered.map(obj => obj?.poanumber).includes(app?.poanumber) ?


                                                        // Disabled State
                                                        (
                                                            <Tooltip
                                                                message={`This agreement has already been linked to this application.`}
                                                                children={
                                                                    <div className='w-full flex flex-row justify-between py-[10px] px-[15px]'>
                                                                        <div className='flex flex-col'>
                                                                            <p className='m-0 font-medium text-[14px] text-[#C4C4C4] uppercase'>
                                                                                {app?.poanumber}
                                                                            </p>
                                                                            <p className='m-0 text-[14px] text-[#C4C4C4]'>
                                                                                {app?.vendor?.vendorname}
                                                                            </p>
                                                                        </div>

                                                                        {/* Add Button */}
                                                                        <img className='w-[28px] cursor-not-allowed' src={AddDisabled} alt='Add'></img>
                                                                    </div>}
                                                            ></Tooltip>

                                                        ) :

                                                        // Active State
                                                        (
                                                            <div className='flex flex-row justify-between py-[10px] px-[15px]'>
                                                                <div className='flex flex-col'>
                                                                    <p className='m-0 font-medium text-[14px] text-[#424242] disabled:text-[#C4C4C4] uppercase'>
                                                                        {app?.poanumber}
                                                                    </p>
                                                                    <p className='m-0 text-[14px] text-[#A0A0A0] disabled:text-[#C4C4C4]'>
                                                                        {app?.vendor?.vendorname}
                                                                    </p>
                                                                </div>

                                                                {/* Add Button */}
                                                                <img className='cursor-pointer w-[28px]' src={Add} alt='Add' onClick={() => addApplication(app)}></img>

                                                            </div>
                                                        )
                                                }
                                            </div>
                                        ))}
                                </div>
                            }

                        </div>

                        {/* ------------------------------------------------------ */}
                        {/*  Information Box                                       */}
                        {/* ------------------------------------------------------ */}

                        <div className='Info-Box'>
                            <div className='flex px-2'>
                                <img className='h-6 px-2' alt='info-icon' src={InfoIcon}></img>
                                <label>To add new agreements to the inventory, click <a href='/inventory?view=agreements' target='_blank'> here</a>.</label>
                            </div>
                        </div>

                    </div>

                    {/* ------------------------------------------------------ */}
                    {/*  Body Container                                        */}
                    {/* ------------------------------------------------------ */}
    
                    <div className='flex flex-col justify-between h-full overflow-hidden'>

                        {/* ------------------------------------------------------ */}
                        {/*  Table Container                                       */}
                        {/* ------------------------------------------------------ */}

                        <div className='Table-Container overflow-hidden'>

                            {/* ======== Sorting and Filters ========== */}
                            <div className='flex flex-row justify-between py-[10px] px-[20px] rounded-tl-[10px] rounded-tr-[10px] border-b-[#D8D8D8] border-solid border-b bg-[#F5F5F5]'>

                                <label className='font-semibold self-center'>
                                    Total: {agreementsFiltered.length} of {agreementList.length}
                                </label>

                                <div className='flex flex-row gap-2'>

                                    {/* ======== Search Bar ======== */}
                                    <div className='grid grid-cols-[30px_1fr] items-center gap-[15px] bg-white px-[5px] rounded-[5px] border-1 border-solid border-[#dee2e6] w-[400px]'>
                                        <img className='ml-[10px]' src={IconSearch} alt='searchInput-icon'></img>
                                        <label htmlFor='searchInput'>
                                            <input
                                                id='searchInput'
                                                className='border-none h-[36px] w-full p-0 m-0 outline-none bg-white'
                                                type='text'
                                                placeholder='Search applications in this agreement'
                                                autoComplete='no'
                                                onChange={(e) => handleTableSearch(e.target.value)}
                                                value={searchInput}
                                            ></input>
                                        </label>
                                    </div>

                                    {/* Download Report */}
                                    <ExportToCSV
                                        filename={`${agreements.vendorname}-Applications-${Date.now()}`}
                                        data={agreementsFiltered}
                                    ></ExportToCSV>

                                </div>

                            </div>

                            {/* ======================= Table ======================= */}
                            <table className='w-full max-h-96 overflow-y-scroll'>
                                <thead>
                                    <tr className='sticky top-0 bg-white border-b-[#D8D8D8] border-solid border-b-2'>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>PoA Number</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Vendor</th>
                                        <th className='py-[15px] px-[18px] font-semibold text-[#212529]'>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        // No agreements found
                                        agreementsFiltered.length === 0 ?
                                            (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className='text-center p-[2%]'>
                                                            No agreements found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                            // Map in each agreement
                                            agreementsFiltered.map((application, index) => (
                                                <tr key={index} className='border-b-[#C8C8C9] bg-[#FFFFFF] border-solid border-b last:border-0'>

                                                    {/* PoA Number */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        {application?.poanumber}
                                                    </td>

                                                    {/* Vendor Name */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        {application?.vendor?.vendorname}
                                                    </td>

                                                    {/* Status */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <StatusLabel status={application?.status}></StatusLabel>
                                                    </td>

                                                    {/* Delete Icon */}
                                                    <td className='py-[15px] px-[18px] max-w-[160px] overflow-hidden'>
                                                        <div className='Cancel-Icon cursor-pointer min-w-[32px]' onClick={() => deleteApplication(application)}></div>
                                                    </td>

                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>

                        </div>

                        {/* ------------------------------------------------------ */}
                        {/*  Buttons Container                                     */}
                        {/* ------------------------------------------------------ */}

                        <div className='flex flex-row my-3 gap-2' >

                            {/* Submit */}
                            <button className='Primary-Button' disabled={false} type='submit' value='submit' onClick={() => handleSubmit()}>
                                Save
                            </button>

                            {/* Cancel */}
                            <button className='Secondary-Button' onClick={() => {
                                setAgreementPane(undefined)
                                setSearchAgreements('');
                                setSearchAgreementsFiltered([]);
                                setSearchInput('');
                            }}>
                                Cancel
                            </button>

                        </div>

                    </div>

                </dialog>
            </div >
        </>
    )
}
